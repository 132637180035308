import { commonTransform } from "../handleTransformation";
import { CANDIDATE_CONFIDENCE_SCORE, CANDIDATE_CONVERTED_STATUS, CANDIDATE_ENGAGEMENT_SCORE } from "services/url.service";

export const candidateMutations = {};

export const candidateQueries = {
  getCandidateConfidenceScore: {
    getUrl: ({ companyId }) => {
        return CANDIDATE_CONFIDENCE_SCORE(companyId)
    },
    transformResponse: (res) => commonTransform(res),
    method: "GET",
  },
  getCandidateConvertedStatus: {
    getUrl: ({filters , companyId}) => {
        return CANDIDATE_CONVERTED_STATUS({ companyId, filters })
    },
    transformResponse: (res) => commonTransform(res),
    method: "GET",
  },
  getCandidatesAverageEngagementScore: {
    getUrl: ({ companyId ,filters, offerAccepted }) => {
        return CANDIDATE_ENGAGEMENT_SCORE({ companyId, filters, offerAccepted })
    },
    transformResponse: (res) => commonTransform(res),
    method: "GET",
  }
};
