import { campaignTemplateQueries } from "./campaignTemplateApis";
import { candidateQueries } from "./candidateApis";
import { domoMutations, domoQueries } from "./domoApis";
import { JourneyTemplateMutations, JourneyTemplateQueries } from "./journeyTemplateApis";
import { touchpointInstancesQueries } from "./touchpointInstancesApis";
import { companyQueries ,companyMutations } from "./companyApis";
import { designationQueries } from "./designationApis";
import { positionQueries } from "./positionsApis";
import { userQueries } from "./userApis";

export const mutations = {
  ...domoMutations,
  ...JourneyTemplateMutations,
  ...companyMutations
}

export const queries = {
  ...domoQueries,
  ...JourneyTemplateQueries,
  ...candidateQueries,
  ...campaignTemplateQueries,
  ...touchpointInstancesQueries,
  ...positionQueries,
  ...companyQueries,
  ...userQueries,
  ...designationQueries
}
