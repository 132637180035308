import { commonTransform } from "../handleTransformation";
import { GET_TOUCHPOINT_INSTANCE_EFFECTIVENESS } from "services/url.service";

export const touchpointInstancesMutations = {};

export const touchpointInstancesQueries = {
    getTouchPointInstanceEffectiveness: {
    getUrl: ({filters , companyId}) => {
        return GET_TOUCHPOINT_INSTANCE_EFFECTIVENESS(companyId, filters)
    },
    transformResponse: (res) => commonTransform(res),
    method: "GET",
  }
};
